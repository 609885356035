/* -----------------------------------------------------tablet----------------------------------------------------- */
@media all and (max-width:1024px){

    #Mappage .cmprsEstmt .allow_building_btn_con {
        top: 3px;
        right: 1%;
    }
    #Mappage .cmprsEstmt .not_allow {
        margin: 50px 0;
    }

    #Mappage .informationWrap .topWrap{
        position:fixed;
        left:0;top:62px;
        width:calc(100% - 20px); height:110px;
        margin:0 10px;
        padding:15px 25px;
    }

    #Mappage .informationWrap.close,
    #Mappage .consulting_con.close {
        height: 8%;
        padding: 0;
    }
    #Mappage .informationWrap .btn_b.close,
    #Mappage .informationWrap .noneBox.close,
    #Mappage #bookmark.close,
    #Mappage .informationWrap .loadingModule.close,
    #Mappage .consultingForm.close,
    #Mappage .cmprsEstmt.close {
        display: none;
    }
    #Mappage #bookmark.infoBox{
        box-shadow:none;
        padding:0;
        height:100%;
    }

    #Mappage .M_side_menu {
        display: block !important;
    }
    #Mappage .side_menu {
        display: none;
    }
    #Mappage .cmprsEstmt .address_info_wrap {
        flex-flow: column nowrap;
    }
    #Mappage .my_page, #Mappage .book_mark {
        display:none;
    }
    #Mappage .header .mobile_mypage {
        display: block !important;
        height: 90%;
        float: right;
        margin: 0;
        padding: 5px;
    }
    #Mappage .header .mobile_mypage img {
        height: 100%;
    }
    #Mappage .header {
        left: 50%; top: 0;
        width: 100%; height: 52px;
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        transform: translateX(-50%);
        background: #434343;
    }
    #Mappage .header .contentWrap .imageBox {
        margin: 0;
        height: auto;
    }
    #Mappage .header .contentWrap {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    #Mappage .header .contentWrap .inputBox input {
        padding: 0 2.8rem 0 1rem;
        font-size: 1rem;
    }
    #Mappage .header .contentWrap .imageBox img.logo {
        margin: 0;
        height: auto;
    }
    #Mappage .header .contentWrap .inputBox img.search {
        right: 9px;
        top: 50%;
        transform: translateY(-50%);
        height: 20px;
    }
    #Mappage .header .contentWrap .inputBox {
        width: 100%;
        border-radius: 8px;
        margin: 0 5px;
    }
    #Mappage .informationWrap {
        left: 0; bottom: 0;
        top: auto;
        width: 100%; height:400px;
        padding:20px 20px 20px 20px;
        min-height: auto;
        background:#fff;
        border-radius:6px;
        box-shadow:0 -3px 9px rgba(0,0,0,0.25);
        z-index:999;
    }
    #Mappage .informationWrap .topWrap .btn_book_mark{
        right:25px; top:15px;
    }
    #Mappage .informationWrap .book_mark{
        z-index:2;
        transition:all 100ms ease-out;
    }
    #Mappage .informationWrap .noneBox.sticky .book_mark{
        top:35px;
    }
    #Mappage .modal {
        text-align: center;
        width: 300px;
        z-index: 999;
        padding: 20px 0;
        bottom: 50%;
    }
    #Mappage .mobile_nav {
        display: flex;
        position: absolute;
        right: 10px;
        top: 180px;
    }

    #Mappage .mobile_nav.bookmark_open{
        top:60px;
    }

    #Mappage .mobile_bookmark {
        display: flex !important;
        box-shadow: 2px 2px 4px 0 rgba(0,0,0,0.25);
        justify-content: center;
        align-items: center;
        width: 30px;
        height: 30px;
        z-index: 99;
        background-color: #fff;
        border-radius: 5px;
        box-shadow: 2px 2px 4px 0 rgba(0,0,0,0.25);
        margin-right: 5px;
    }
    #Mappage .mobile_bookmark img {
        height: 70%;
    }
    #Mappage .mobile_menu_dot {
        display: flex !important;
        width: 30px;
        height: 30px;
        z-index: 99;
        border-radius: 5px;
        justify-content: center;
        align-items: center;
    }
    #Mappage .mobile_menu_dot .mobile_menu {
        height: 70%;
        line-height: 40px;
    }
    #Mappage .side_menu {
        width:30px;
        max-height: 302px;
        right: 2.5%;
        top: 215px;
    }
    #Mappage .side_menu.bookmark_open{
        top:100px;
    }

    #Mappage .side_menu div{
        font-size:8px;
    }

    #Mappage .side_menu .custom_menu_wrap img{
        height:14px;
    }

    #Mappage .side_menu .custom_menu_wrap{
        width:30px;
    }

    #Mappage .side_menu .custom_menu_wrap, #Mappage .side_menu .zoom_menu_wrap {
        border-radius: 5px;
    }
    #Mappage .side_menu .zoom_menu_wrap{
        display:none;
    }
    #Mappage .informationWrap .btn_b {
        position:fixed;
        right:20px; bottom:20px;
        backdrop-filter:blur(5px);
        z-index: 9999;
    }
    #Mappage .informationWrap .btn_b:after,
    #Mappage .informationWrap .btn_b:before{
        background:#7c259b;
    }
    #Mappage .informationWrap .btn_b.black {
        bottom:40px;
        padding: 7px 25px 7px 5px;
        backdrop-filter:none;
        z-index: 9999;
    }
    #Mappage .informationWrap .btn_b.black:after,
    #Mappage .informationWrap .btn_b.black:before{
        background:#000;
    }

    #Mappage .informationWrap .btn_b:hover:after,
    #Mappage .informationWrap .btn_b:hover:before{margin-right:0;}
    #Mappage .btn_b .left-chevron{
        background: linear-gradient(to right, #563DF1, #A817B5 100%);
    }
    #Mappage .informationWrap .green_belt{
        padding-top:10px;
        font-size:18px;
        color:#A817B5;
    }

    .footer {
        display: none !important;
    }
    #Mappage .cmprsEstmt {
        height: 75% !important;
        z-index: 9;
        border: none;
        padding: 20px 10px;
        flex-direction: column;;
    }
    #Mappage .consulting_con .head_wrap .content{
        padding:0px;
    }
    #Mappage .cmprsEstmt .bookmark .title{
        font-size:16px;
    }
    #Mappage .cmprsEstmt .head_wrap{
        width:100%; margin-top:0;
    }
    #Mappage .cmprsEstmt .selectBox{
        margin-top:0;
    }

    #Mappage .cmprsEstmt.close {
        height: 8% !important;
    }
    #Mappage .tabContent.bookmarks:after {
        display: none;
    }
    #Mappage .cmprsEstmt .table_head {
        display: none;
    }
    #Mappage .cmprsEstmt .all_info {
        display: flex;
        align-items: end;
    }
    #Mappage .cmprsEstmt .info_title_M {
        width: 48%;
        height: 100%;
        display: flex;
        flex-flow: column nowrap;
        justify-content: space-between;
        text-align: end;
    }
    #Mappage .cmprsEstmt .info_title_M > p {
        font-weight: 700;
        padding: 0 50px 0 0;
        height: 28px;
        line-height: 28px;
        color: #737373;
        min-width: 70px;
    }
    #Mappage .cmprsEstmt .info_title_M > p:last-child {
        border: 0;
    }
    #Mappage .cmprsEstmt .address_info {
        width: 52%;
    }
    #Mappage .cmprsEstmt .info {
        width: 100%;
        overflow: scroll;
        border: none;
    }
    #Mappage .cmprsEstmt .allowBuildingList{
        font-size:16px; font-weight:600;

    }
    #Mappage .cmprsEstmt .info{
        padding:0;
    }
    #Mappage .cmprsEstmt .address_info_wrap > div{
        width:100%;
        border-left:0;
        border-bottom:1px solid #ccc;
    }
    #Mappage .cmprsEstmt .address_info .content span{
        display:none;
    }
    #selectWrap::after{
        top:59%;
    }


    #Mappage .consulting_con{
        left:0; bottom:0;
        width:100%; height:auto;
        z-index:1000;
    }
    #Mappage .consulting_con .width_con {
        height: auto;
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        padding: 40px 0 80px;
    }
    .consulting_con .left_con , .consulting_con .right_con{
        width: 70%;
        margin:0 50px;
    }

    .consulting_con .left_con:after{
        background:transparent;
    }
    #Mappage .consulting_con .submit_btn {
        width: 200px;
        height: 30px;
        font-size: 16px;
        padding: 0;
        line-height: 30px;
    }
    #Mappage .consulting_con .consulting_detail_input, .consulting_con .consulting_select, .consulting_con .consulting_input {
        padding: 5px 12px;
    }
    #Mappage .consulting_con .consulting_detail_input {
        padding: 12px;
    }
    .consulting_con .consulting_select_title {
        padding: 7px 0;
    }
    #Mappage .informationWrap .M_infoBtn_wrap{
        position:absolute;
        width:100%; height:30px;
        left:0; top:0;
        z-index:9999;
    }
    #Mappage .informationWrap .M_infoBtn {
    position: absolute;
    width: 30%; height: 20px;
    line-height: 30px;
    left: 50%; top:-2px;
    transform: translateX(-50%);
    border-radius: 100px;
    text-align: center;
    }
    #Mappage .informationWrap .infoBtn_line {
        background-color: #c9c9c9;
        width: 50%;
        height: 2px;
        margin: 0 auto;
        margin-top: 9px;
    }
    #Mappage .info_bookmark {
        display: block;
        height:15px;
    }
    #Mappage .cmprsEstmt .bookmark {
        margin: 0;
    }
    .input_cover:nth-of-type(2) {
        height: 100px;
    }
    #Mappage .cmprsEstmt .address_info .content {
        text-align: left;
        padding: 0 0 0 50px;
    }
    

/* -----------------------------------------------------addBookMark(tablet,mobile)----------------------------------------------------- */
    #Mappage .bookmark_window,
    #Mappage .subcategory_window,
    #Mappage .consulting_con{
        position:fixed;
        left:50%; top:50%;
        transform:translate(-50%,-50%);
        width:80vw; height:450px;
        min-width:260px;
        border:none;
        border-radius:10px;
        border:solid 1px #434343;
        z-index:999999;
    }
    #Mappage .bookmark_window.hidden,
    #Mappage .subcategory_window.hidden{
        opacity:0;
        transform:translate(-50%,-45%);
        pointer-events: none;
        border:none;
    }
    #Mappage .consulting_con .width_con .left_con,
    #Mappage .consulting_con .width_con .right_con
    {
        width:100%;
        padding:0;
    }
    #Mappage .consulting_con .width_con{
        height:100%;
        padding:20px 10px;
        overflow-y:scroll;
    }

    #Mappage .consulting_con .submit_btn{
        bottom:10px;
    }

    #Mappage .bookmark_window .content_wrap{
        height:384px;
    }

    #Mappage .bookmark_window_cover{
        position:fixed;
        width:100vw; height:100vh;
        left:0; top:0;
        background:rgba(0,0,0,0.3);
        backdrop-filter:blur(1px);
        z-index:99999;
    }
    #Mappage .bookmark_window_cover.hidden{
        opacity:0;
        pointer-events: none;
        border:none;

    }
    #Mappage .sub_category_list .title{
        width:120px;

    }
    #Mappage .subcategory_window .content_wrap{
        height: 320px;
    }

/* -----------------------------------------------------information(tablet,mobile)----------------------------------------------------- */


    #Mappage .informationWrap .infoBox .tabContent {
        height: auto;
        padding: 30px 0;
        border:none;
        height:auto !important;
    }
    #Mappage .informationWrap .noneBox.sticky .infoBox .tabContent{
        margin-top:120px;
    }
    
    #Mappage .informationWrap .infoBox .tabContent .content_wrap{
        margin-bottom:60px;
    }
    #Mappage  .informationWrap .noneBox{
        position:static;
        height: 100%;
        overflow-y:scroll;
    }
    #Mappage  .informationWrap .noneBox::-webkit-scrollbar {
        display:none;
    }
    #Mappage .informationWrap .infoBox .tabMenuUl{
        position:relative;
        display:flex;
        padding:2px;
        justify-content: space-between;
        border: none; border-radius:11px;
        background:#f7f7f7;
        box-shadow:inset 0px 1px 3px rgba(0,0,0,.15);
        z-index:1;
        
    }
    #Mappage .informationWrap .noneBox.sticky .infoBox .tabMenuUl{
        position:absolute;
        top:65px;
        width:calc(100% - 40px);
    }
    #Mappage .informationWrap .infoBox.cover_hidden:before{
        display:none;
    }
    #Mappage .informationWrap .infoBox .tabMenuUl .tabMenu{
        margin-top:0; padding:10px 0;
        font-size:12px;
        border-radius:10px;
        border:none;
        background:none;
    }
    #Mappage .informationWrap .infoBox .tabMenuUl .tabMenu.active{
        border:none;
        background:#fff;
        font-size:12px;
        margin-top:0;
        color:#111111;
    }
    #Mappage .informationWrap .infoBox .tabMenuUl .tabMenu.active:after,
    #Mappage .informationWrap .infoBox .tabMenuUl .tabMenu.active:before{
        display:none;

    }
    #Mappage .informationWrap .titleBox{
        position:relative;
        font-size:16px; font-weight:600;
        z-index:2;
    }

    #Mappage .informationWrap .subTitleBox{
        position:relative;
        margin-top:10px;
        font-size:12px; font-weight:500;
        z-index:3;
    }

    #Mappage .informationWrap .topWrap .allowWrap{
        margin-top:16px;
    }


    #Mappage .allowWrap {

    }

    #Mappage .informationWrap .topWrap .allowWrap .content{
        flex:1;
    }

    #Mappage .informationWrap .topWrap .content .selectWrap {
        width:100%;
    }

    #Mappage .informationWrap .topWrap .content .selectWrap .allowBuildingList{
        width:100%;
    }


    #Mappage .informationWrap .topWrap .allowWrap .more_btn{

    }
    #Mappage .informationWrap .bottomWrap{
        width:100%; height:100%;
    }
    #Mappage .informationWrap .bottomWrap:before{
        content:'';
        position:absolute;
        width:100%; height:1px;
        left:0; top:70px;
        background:#ededed;

    }

    #Mappage .informationWrap .bottomWrap .bigTabMenuWrap .bigTabMenu.selected{
        background:none;
        box-shadow:none;
        border-bottom: 2px solid transparent;
        border-image: linear-gradient(to right, #563DF1, #A817B5) 1;
    }
    #Mappage .informationWrap .bottomWrap .bigTabMenuWrap .bigTabMenu{
        background:none;
        box-shadow:none;
        transition: all 250ms ease-out , border 0s;
    }

    #Mappage .informationWrap .bottomWrap .infoBox{
        padding:0;
        margin-top:10px;
    }

    #Mappage .informationWrap .noneBox.sticky .subTitleBox{
        position:absolute;
        top:20px ;
    }

    #Mappage .informationWrap .infoBox .tabContent .content_wrap .content_box.totalPrice{
        position: absolute;
        bottom: -66px;
        margin:0;
        padding:17px 0;
        border-top:dashed 1px 
    }
    #Mappage .informationWrap .infoBox .tabContent .content_wrap .content_box.totalPrice:after{
        position:absolute;
        left:76px; top:15px;
        width:1px; height:calc(100% - 27px);
        content:'';
        background:rgba(17,17,17,.2);
    }
    #Mappage .informationWrap .infoBox .tabContent .content_wrap .content_box .title{
        font-size:12px;
    }
    #Mappage .informationWrap .infoBox .tabContent .content_wrap .content_box .content{
        font-size:15px;
    }
    #Mappage .informationWrap .infoBox .tabContent .content_wrap.tab_04{
        margin-bottom:80px;
    }
    #Mappage .informationWrap .infoBox .tabContent .content_wrap .content_box.mobile_hide{
        display:none;
    }

    #Mappage .informationWrap .noneBox.sticky .infoBox .tabContent .content_wrap .content_box.allowBuilding{
        
    }
    #Mappage .informationWrap .topWrap .content .allowBuildingList{
        height:28px; font-size:14px; font-weight:500;
    }
    #Mappage .informationWrap .topWrap .content .allowBuildingTitle{
        font-size:11px; font-weight:500;
    }

    #Mappage .informationWrap .topWrap .allowWrap .more_btn{
        height:28px;
        font-size:11px; font-weight: 600;
        line-height:28px;
    }

    #Mappage .informationWrap .topWrap .selectWrap::after{
        top:50%;
    }

    #Mappage .informationWrap .infoBox .tabContent .content_wrap .content_box.allowBuilding{
        position:absolute;
        top:-50px;
        margin:0;
    }
    #Mappage .more_btn{
        width:50px;
        padding:0 8px;
    }
    #Mappage .more_btn:after,
    #Mappage .more_btn:before{
        top:13px;
    }
    #Mappage .informationWrap .infoBox .mobile_allowBuilding{
        display:block;
        position:relative;
        width:100%;
        margin-top:20px; padding:0 5px;
        z-index:1;

    }
    #Mappage .informationWrap .infoBox .mobile_allowBuilding .title{
        display: inline-block;
        width: 67px;
        margin: 0 21px 0 0;
        text-align: right;
        font-size: 12px;
        font-weight: 600;
        line-height: 1em;
        margin-top: 3px;
        vertical-align: top;
        color: #737373;
    }
    #Mappage .informationWrap .infoBox .mobile_allowBuilding .allowBuildingList{
        position: relative;
        width: 100%;
        padding: 0 5px;
        height: 24px;
        font-size: 14px;
        font-weight: 800;
        border-radius: 5px;
        box-shadow: 0px 1px 1px #000;        
    }
    #Mappage .informationWrap .infoBox .mobile_allowBuilding .content{
        display:inline-block;
        width:calc(100% - 144px);
        font-size:15px;
        color:#111;
        font-weight:800;
    }
    #Mappage .informationWrap .noneBox.sticky .mobile_allowBuilding{
        position:absolute;
        top:105px;
        width:90%;
    }
    #Mappage .header .transaction.black:after{
        width:30px;
        height:30px;
        margin-top:1px;
    }
    #Mappage .header .transaction {
        height:90%;
        padding:5px;
    }
    #Mappage .header .transaction img {
        margin-top:1px;
        height:100%;
    }

    #Mappage .transaction_option{
        left:0; top: 180px;
        width:calc(100% - 20px); height:150px;
        margin: 0 10px; 
        padding:10px 20px;
        z-index:99;
    }

    #Mappage .transaction_option .close_btn{
        top:20px; right:10px;
    }
    #Mappage .transaction_option .submit_btn{
        margin: 10px auto;
        margin-top:5px !important;
    }
}

/* -----------------------------------------------------mobile----------------------------------------------------- */

@media all and (max-width:768px){

    .consulting_con .left_con , .consulting_con .right_con{
        width: 90%;
        margin: 0;
    }

    

}
@media all and (max-width:400px){
    #Mappage .bookmark_window,
    #Mappage .subcategory_window{
        max-width:280px;
    }

    #Mappage .cmprsEstmt .address_info {
        width: 100%;
    }
    #Mappage .cmprsEstmt .info_title_M p {
        padding: 0;
    }
    #Mappage .cmprsEstmt .all_info {
        justify-content: space-between;
    }
}

@media all and (max-width:360px){
    #Mappage .more_btn{
        width:56px;
    }
}