
@import url('https://fonts.googleapis.com/css2?family=Nanum+Gothic:wght@400;700;800&family=Noto+Serif:wght@700&family=Noto+Sans:wght@800&display=swap');
@font-face {
    font-family: 'Pretendard';
    src: url('../../public/fonts/Pretendard-Black.otf') format('opentype');
    font-weight: 900;
    font-style: normal;
}
@font-face {
    font-family: 'Pretendard';
    src: url('../../public/fonts/Pretendard-ExtraBold.otf') format('opentype');
    font-weight: 800;
    font-style: normal;
}
@font-face {
    font-family: 'Pretendard';
    src: url('../../public/fonts/Pretendard-Bold.otf') format('opentype');
    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-family: 'Pretendard';
    src: url('../../public/fonts/Pretendard-SemiBold.otf') format('opentype');
    font-weight: 600;
    font-style: normal;
}
@font-face {
    font-family: 'Pretendard';
    src: url('../../public/fonts/Pretendard-Medium.otf') format('opentype');
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: 'Pretendard';
    src: url('../../public/fonts/Pretendard-Regular.otf') format('opentype');
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: 'Pretendard';
    src: url('../../public/fonts/Pretendard-Light.otf') format('opentype');
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: 'Pretendard';
    src: url('../../public/fonts/Pretendard-ExtraLight.otf') format('opentype');
    font-weight: 200;
    font-style: normal;
}
@font-face {
    font-family: 'Pretendard';
    src: url('../../public/fonts/Pretendard-Thin.otf') format('opentype');
    font-weight: 100;
    font-style: normal;
}
@font-face{
    font-family: "SUITE";
    src:url('../../public/fonts/SUITE-Heavy.otf') format('opentype');
    font-weight:600;
}
@font-face{
    font-family: "SUITE";
    src:url('../../public/fonts/SUITE-Medium.otf') format('opentype');
    font-weight:500;
}
@font-face{
    font-family: "SUITE";
    src:url('../../public/fonts/SUITE-Regular.otf') format('opentype');
    font-weight:400;
}
/* 이후 "SDGothic"으로 폰트 적용 가능 */
/* reset */
* {box-sizing:border-box;}
html,body,h1,h2,h3,h4,h5,h6,
p,div,ol,ul,li,dl,dt,dd,address,blockquote,video,
em,strong,span,sup,sub,del,s,q,a,img,
table,thead,tbody,tfoot,th,tr,td,
form,fieldset,legend,input,select,option,button,textarea,label,optgroup,
header,main,footer,nav,section,article,aside,figure,figcaption,details
{
    font-family: "Pretendard", sans-serif;
    color:#222; letter-spacing:-0.02em;
    font-weight:normal; font-size:14px; font-style:normal;
    line-height:1.0; margin:0; padding:0;
    border:0; background:none;
}
ul,ol,li {list-style:none;}
a {text-decoration:none; color:#111;}
table,tr,td,th {border-collapse:collapse;text-align:left;}
button {cursor:pointer;}
.skip {display:none;}
html, body {
    overscroll-behavior: none; /* x, y 모든 축에서 오버스크롤 동작을 막음 */
  }