/*  #BACKROUND  */
#news {
    margin: 0 5%;
}
div#backRound {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: -2;
}

@keyframes dashRotate {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.dashRound {
    position: absolute;
    width: 30vw;
    height: 30vw;
    border-radius: 50%;
    border: 1px dashed #222;
    animation: dashRotate 360s linear infinite;
}

div#backRound > div:nth-child(1) {
    top: 6vw;
    left: -2vw;
}
div#backRound > div:nth-child(2) {
    top: 1vw;
    right: -3vw;
}
div#backRound > div:nth-child(3) {
    top: -10vw;
    right: -3vw;
}

/*  SECTION  */
section {
    transform: translateY(-50%);
    top: 50%;
    left: 0;
    position: absolute;
    width: 100%;
}
section > div {
    float: left;
}

/*  #NEWSLEFT  */
div#newsLeft {
    width: 33vw;
    padding: 0 5vw 0 12vw;
    position: relative;
}
div#newsLeft h2 {
    font-weight: 200;
    font-size: 64px;
}
div#newsLeft div#newsMenu {
    font-size: 1.3vw;
    font-weight: 500;
    padding-top: 3vw;
}
div#newsLeft div#newsMenu ul {
    display: flex;
    gap: 1vw;
    flex-direction: column;
    line-height: 160%;
}
div#newsLeft p {
    padding-top: 9vw;
    font-size: 0.8vw;
    font-weight: 200;
    line-height: 160%;
}
div#newsLeft div#newsMenu ul li {
    cursor: pointer;
    font-size: 1.4rem;
}
div#newsLeft div#newsMenu ul li.underline {
    text-decoration: underline;
    text-underline-offset: 5px;
}

/*  HOVER  */
div#newsLeft div#newsMenu ul li:hover {
    text-decoration: underline;
    text-underline-offset: 5px;
}

/*  #NEWSRIGHT  */
div#newsRight {
    padding-top: 10px;
    width: 67vw;
    overflow: hidden;
}

/* container 자체는 가로 스크롤 박스 */
#container {
    overflow-x: auto;
    overflow-y: hidden;
    display: block;
    width: 100%;
    /* 스크롤바 가리기 */
    scrollbar-width: none; /* Firefox */
}
#container::-webkit-scrollbar {
    display: none; /* Chrome 등 */
}

/* 
 UL: 2행 레이아웃을 자동으로 확장
 - grid-auto-flow: column => 열을 우선 확장
 - grid-template-rows: 2개 행
 - grid-auto-columns: 각 열의 너비(21vw)
 - gap: 열/행 간격 3vw
*/
#container ul {
    display: grid;
    grid-auto-flow: column;
    grid-template-rows: repeat(2, auto);
    grid-auto-columns: 21vw;
    column-gap: 3vw;
    row-gap: 3vw;
    /* 스크롤바 안 보이게 */
    scrollbar-width: none;
}
#container ul li:nth-child(odd) {
    grid-row: 1;
}
#container ul li:nth-child(even) {
    grid-row: 2;
}

/* li의 하단 테두리 등 기존 스타일 */
#container ul li a {
    display: block;
}
#container ul li {
    border-bottom: 1px dashed #222;
}

/* .bgimg 내부 스타일 */
.bgimg {
    display: block;
    background-size: cover;
    background-position: center center;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.contentsWrap {
    position: relative;
    height: 12vw;
    width: 100%;
}
#container .videoTitle {
    position: absolute;
    color: #fff;
    bottom: 0;
    left: 0;
    opacity: 0;
    padding: 15px 20px;
    text-transform: uppercase;
    background-image: linear-gradient(
        135deg,
        rgba(162, 23, 181, 0.9) 20%,
        rgba(86, 61, 241, 0.9) 100%
    );
    width: 100%;
    transition: opacity 0.3s ease-in-out;
    line-height: 140%;
    /* white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden; */
    font-size: 1vw;
}

/* 제목, etc */
#container .videoCategory {
    padding: 5px 0;
    display: block;
    font-size: 1.1vw;
    font-weight: 500;
    white-space: nowrap;
    /* 한 줄로 표시 */
    overflow: hidden;
    /* 넘치는 부분 숨김 */
    text-overflow: ellipsis;
    /* 넘치는 텍스트는 ... 처리 */
}

/* 호버시 bgimg div 표시 */
#container li:hover .videoTitle {
    opacity: 1;
}

/* ------------------------------
 반응형
------------------------------ */
@media (max-width: 1300px) {
}

@media (max-width: 780px) {
    /* 데스크톱 전용 “2행 가로 스크롤” → 모바일에서는 세로로 */
    #container .videoCategory {
        font-size: 1rem;
    }
    section {
        margin-top: 20vh;
        transform: translateY(0);
        top: 0;
        left: 0;
        position: unset;
    }
    section > div {
        float: none;
    }
    div#newsLeft {
        width: 100%;
        padding: 0;
    }
    div#newsLeft h2 {
        font-weight: 200;
        font-size: 64px;
        width: 50%;
    }
    div#newsLeft div#newsMenu {
        font-size: 20px;
        font-weight: 500;
        padding-top: 3vw;
        width: 50%;
    }
    .contentsWrap {
        height: auto;
    }
    div#newsLeft p {
        width: 50%;
        font-size: 1.8vw;
        float: right;
        text-align: right;
        padding: 0;
    }
    div#newsRight {
        padding: 12vw 0;
        width: 100%;
        height: auto;
    }
    #container {
        height: auto;
        overflow: visible;
    }
    /* 모바일에서는 flex-wrap으로 세로 쌓기 */
    #container ul {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;
        margin: auto;
        row-gap: 7vw;
        column-gap: 0;
        justify-content: space-between;
    }
    #container ul li {
        width: 49%;
    }
    #container .bgimg {
        height: 22vw;
    }
    #container span {
        font-size: 20px;
    }
    #container .bgimg ~ div p {
        font-size: 16px;
    }
}

@media (max-width: 480px) {
    .dashRound {
        position: fixed;
        width: 60vw;
        height: 60vw;
    }
    div#backRound > div:nth-child(1) {
        top: 15vw;
    }
    div#backRound > div:nth-child(2) {
        top: 75vh;
        right: 10vw;
    }
    div#backRound > div:nth-child(3) {
        top: 75vh;
    }
    div#newsLeft {
        display: flex;
        flex-direction: column;
    }
    div#newsLeft h2 {
        font-size: 48px;
    }
    div#newsLeft div#newsMenu {
        font-size: 16px;
        padding-top: 8vw;
    }
    div#newsLeft div#newsMenu ul {
        flex-direction: row;
        gap: inherit;
        justify-content: space-between;
    }
    div#newsLeft p {
        font-size: 14px;
        width: 100%;
        margin: 6vw 0;
        text-align: left;
    }
    #container {
        padding-bottom: 15vw;
    }
    #container ul {
        width: 100%;
        row-gap: 8vw;
    }
    #container ul li {
        width: 100%;
    }
    #container .bgimg {
        height: 52vw;
    }
    #container li .bgimg ~ div {
        opacity: 1;
        top: 52vw;
        height: 20vw;
        background-image: none;
        padding: 10px 0;
    }
    #container span {
        padding: 20vw 0 5px;
    }
}
